import { React, createContext, useContext, useState, useEffect } from 'react'

const defaultState = {
  formOpen: false,
  formType: 'QUOTE',
  setFormOpen: () => {},
  setFormType: () => {},
}

// const types = ['MESSAGE', 'QUOTE', 'COLLAB', 'CARRIER']

const FormContext = createContext(defaultState)

export const FormProvider = ({ children }) => {
  const [formOpen, setOpen] = useState(defaultState.formOpen)
  const [formType, setType] = useState(defaultState.formType)

  const setFormOpen = (openBool) => {
    setOpen(openBool)
  }

  const setFormType = (newType) => {
    setType(newType)
  }

  // Add click handler for banner form actions
  useEffect(() => {
    const handleBannerClick = (e) => {
      const bannerElement = e.target.closest('.banner-form-action');
      if (bannerElement) {
        const formType = bannerElement.getAttribute('data-form');
        if (formType) {
          setFormType(formType);
          setFormOpen(true);
        }
      }
    };

    document.addEventListener('click', handleBannerClick);
    return () => document.removeEventListener('click', handleBannerClick);
  }, []);

  return (
    <FormContext.Provider
      value={{
        formOpen,
        formType,
        setFormOpen,
        setFormType,
      }}>
      {children}
    </FormContext.Provider>
  )
}

export default FormContext

export const useFormContext = () => useContext(FormContext)
